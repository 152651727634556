import React, {useCallback} from 'react';
import {
  LegacyCard,
  TextField,
  LegacyStack,
  ChoiceList,
  Link,
  Icon,
  Button,
  Bleed,
  Box,
  Divider,
} from '@shopify/polaris';
import {DISCOUNT_TYPES, LINKS} from '../../../../../constants/constants';
import { InfoIcon } from "@shopify/polaris-icons";
import update from 'immutability-helper';
import DiscountFuncWarningBanner from '../discountWarningBanner/discountFuncWarningBanner';
import DiscountCart from '../discountCart/discountCart';
import CardDivider from '../../../../../components/cardDivider';
import './discountCartV2.css';

const DiscountCartV2 = (props) => {
  const {discount, state, setState, productIndex, isAi = false, currency, currencySymbol = '$'} = props;

  const handleDiscountChange = useCallback((type) => {
    const newDiscount = {
      sym: discount?.sym && currencySymbol === discount.sym ? currencySymbol : '%',
      value: '',
      message: '',
    }
    if (type === 'script') {
      setState(update(state, { 
        ruleFeatures: { $set: { isDiscountFunctionEnabled: false } },
        product: {
          [productIndex]: {
            compareAtPrice: { $set: newDiscount }
          }
        }
      }))
    } else {
      setState(update(state, { 
        ruleFeatures: { $set: { isDiscountFunctionEnabled: true } },
        product: {
          [productIndex]: {
            compareAtPrice: { $set: newDiscount }
          }
        }
      }))
    }
  }, [state, setState]);

  const handleDiscountTypeChange = useCallback((discountType) => {
    const newDiscount = {
      sym: discountType === 'percentage' ? '%' : currencySymbol,
      value: discount?.value ? discount.value : '',
      message: discount?.message ? discount.message : 'DISCOUNT',
    }

    if (Array.isArray(state.product)) {
      setState(update(state,{ product: {[productIndex]: { compareAtPrice: { $set: newDiscount }}}}))
    }else{
      setState(update(state, { compareAtPrice: { $set: newDiscount }}))
    }
  }, [state, setState, discount, productIndex]);

  const handleDiscountValueChange = useCallback((value) => {
    const newVal = discount?.sym && "%" === discount.sym && value > 100 ? 100 : value;
    setState(update(state, {
      product: {
        [productIndex]: {
          compareAtPrice: { $set: {
              sym: discount?.sym && currencySymbol === discount.sym ? currencySymbol : '%',
              value: newVal,
              message: discount?.message ? discount.message : 'DISCOUNT',
            }
          }
        }
      }
    }));
  }, [state, setState, discount, productIndex]);

  const handleDiscountMessageChange = useCallback((value) => {
    setState(update(state,{
      product: {
        [productIndex]: {
          compareAtPrice: { $set: {
              sym: discount && discount.sym && currencySymbol === discount.sym ? currencySymbol : '%',
              value: discount && discount.value ? discount.value : '',
              message: value,
            }
          }
        }
      }
    }))
  }, [state, setState, discount, productIndex]);

  const cardActions = [
    {
      content:
        state?.ruleFeatures?.isDiscountFunctionEnabled
          ? <Button  onClick={() => handleDiscountChange('script')} variant="plain">Switch to Scripts</Button>
          : <Button  onClick={() => handleDiscountChange('function')} variant="plain">Switch to Functions</Button>
    },
    {
      content: (
        <div className='DiscountInfoIconWrapper'>
          <Link external url={LINKS.shopifyFunctionsHelp}>
            <Icon source={InfoIcon} tone="subdued"/>
          </Link>
        </div>
      )
    },
  ];

  return (
    <div className='DiscountCartWrapper'>
      <LegacyCard sectioned title={state?.ruleFeatures?.isDiscountFunctionEnabled ? 'Discount' : 'Compare at price'} actions={cardActions}>
      
        
        {
          state?.ruleFeatures?.isDiscountFunctionEnabled
            ?
              <div>
                <Bleed marginInline="400">
                <Box paddingBlockEnd="400">
                    <Divider borderColor="border"/>
                </Box>
              </Bleed>
              <LegacyStack vertical={true}>
                <ChoiceList
                  title="Type"
                  choices={DISCOUNT_TYPES}
                  selected={discount && discount.sym && discount.sym !== '%' ? 'fixedAmount' : 'percentage'}
                  onChange={(value) => handleDiscountTypeChange(value[0])}
                />
                <TextField
                  label='Discount value'
                  autoComplete='off'
                  value={discount && discount.sym ? String(discount.value) : ""}
                  max={discount && discount.sym && "%" === discount.sym ? 100 : null}
                  type="number"
                  onChange={(val) => handleDiscountValueChange(val)}
                />
                <TextField
                  label='Discount message'
                  placeholder='e.g. “DISCOUNT”'
                  autoComplete='off'
                  type='text'
                  maxLength={250}
                  value={discount && discount.message ? discount.message : "DISCOUNT"}
                  onChange={(val) => handleDiscountMessageChange(val)}
                />
                {/* <DiscountFuncWarningBanner handleDiscountChange={handleDiscountChange}/> */}
              </LegacyStack>

              </div>
            :
            <div style={{marginTop: '-1rem'}}>
              <DiscountCart
                state={state}
                setState={setState}
                currency={currency}
                currencySymbol={currencySymbol}
                isAi={isAi}
                index={productIndex}
                isNewDiscountVersion={true}
              />
            </div>
        }
      </LegacyCard>
    </div>
  );
};

export default DiscountCartV2;